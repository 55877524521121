(function($) {
    $(function() {
        refreshSurvey();
    });
    var refreshSurvey = function() {
        $.get(window.SURVEY_STATE_URL, function(data) {
           if (data.callback) {
               eval(data.callback);
           }
           setTimeout(function(){refreshSurvey();}, 5000);
        });
    };
})($);